<template>
  <div class="report-post CreatorPostReport">
    <div v-gap="['', $mpadding, $mpadding, $mpadding]">
      <div class="report-post-title" slot="header">
        <p strong>{{ $locale["words"]["report_post"] }}</p>
      </div>
      <p class="report-post-why">{{ $locale["report"]["why"] }}</p>
      <div class="report-post-reasons">
        <ListItemCheck
          class="__item"
          :text="$locale['report']['fake_info']"
          @click.native="selectReport({ reason: 'fake_info' })"
          :checked="reason === 'fake_info'"
        />
        <ListItemCheck
          class="__item"
          :text="$locale['report']['violent_content']"
          @click.native="selectReport({ reason: 'violent_content' })"
          :checked="reason === 'violent_content'"
        />
        <ListItemCheck
          class="__item"
          :text="$locale['report']['fraud']"
          @click.native="selectReport({ reason: 'fraud' })"
          :checked="reason === 'fraud'"
        />
        <ListItemCheck
          class="__item"
          :text="$locale['report']['hate_speech']"
          @click.native="selectReport({ reason: 'hate_speech' })"
          :checked="reason === 'hate_speech'"
        />
        <ListItemCheck
          class="__item"
          :text="$locale['report']['related_a_minor']"
          @click.native="selectReport({ reason: 'related_a_minor' })"
          :checked="reason === 'related_a_minor'"
        />
        <ListItemCheck
          class="__item"
          :text="$locale['report']['other']"
          @click.native="selectReport({ reason: 'other' })"
          :checked="reason === 'other'"
        />
      </div>
      <div class="report-post-message">
        <TextareaRich name="message" :placeholder="$locale['object']['incentive']['tip-message-placeholder']" @input="inputMessage" />
      </div>
      <div class="report-post-options">
        <a class="primary-button __send" block @click="sendReport" v-if="reason">
          <iconic v-if="$loading" name="gspinner" />
          <span v-else>Send report</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post", "modal"],
  components: {
    TextareaRich: () => import("../widgets/TextareaRich.vue"),
  },
  data: function() {
    return {
      reason: null,
      message: "",
    };
  },
  methods: {
    open: function() {
      this.$refs.modal.open();
    },
    modalClose: function() {
      this.modal.close();
    },
    selectReport: function({ reason }) {
      this.reason = reason;
    },
    inputMessage: function({ target: { innerHTML } }) {
      this.message = innerHTML;
    },
    sendReport: async function(data = {}) {
      this.isLoading(true);
      try {
        data.postId = this.postReport._id;
        data.reason = this.reason;
        data.message = this.message;
        data.creatorId = this.postReport.user._id;
        const response = await this.$api.post(`${process.env.VUE_APP_API_HOST}/posts/report`, data);
        this.reportResponse(response);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error, icon: "error" });
      }
      this.isLoading(false);
    },
    reportResponse: function(response) {
      if (response?.data?.success) {
        this.modalClose();
        this.ialert.go({ type: "notification", title: "Success", message: response.data.message, icon: "success", "icon-cog": { original: true } });
      } else {
        this.ialert.go({ type: "notification", title: "Error", message: response.data?.message, icon: "error" });
      }
    },
  },
  computed: {
    postReport: function() {
      return this.post;
    },
  },
};
</script>

<style lang="scss">
.report-post {
  &-title {
    padding: $mpadding;
    font-weight: bold;
  }
  &-why {
    text-align: center;
    padding: $mpadding 0 $mpadding * 2 0;
  }
  &-reasons {
    border: solid 1px $alto;
    border-radius: $mradius;
    overflow: hidden;
    .list-item.__item {
      padding: $mpadding;
    }
  }
  &-message {
    padding: $mpadding 0 $mpadding 0;
    [name="message"] {
      border-radius: $mradius;
    }
  }
  &-options {
    padding: 0 0 0 0;
    text-align: center;
    .__send {
      padding: $mpadding/1.3;
    }
  }
}
</style>
